import { PageProps, graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'

import Layout from '@talentinc/gatsby-theme-ecom/components/Layout'

import SEO from '@talentinc/gatsby-theme-ecom/components/SEO'
import SearchPageTemplate from '@talentinc/gatsby-theme-ecom/components/Templates/SearchPage'
import { SearchPosts } from '@talentinc/gatsby-theme-ecom/types/landingPage'
import {
  PageContextType,
  PageDataContextType,
} from '@talentinc/gatsby-theme-ecom/types/page'

import Footer from '../components/Footer'
import HeaderV2 from '../components/HeaderV2'

const Search: React.FC<
  PageProps<
    { localSearchPosts: SearchPosts } & PageDataContextType,
    PageContextType
  >
> = (props) => {
  const { localSearchPosts, ...pageData } = props.data
  const { t } = useTranslation()

  return (
    <Layout pageTitle={t('search.pageTitle')} pageData={pageData}>
      <SEO noIndex={props.pageContext.noIndex} />
      <HeaderV2 showBlogNavigation />
      <SearchPageTemplate localSearchPosts={localSearchPosts} />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query SearchPage($brandName: String!) {
    localSearchPosts: localSearchPosts {
      index
      store
    }
    brand: contentfulBrand(name: { eq: $brandName }) {
      ...Brand
    }
    headerV2: contentfulHeaderV2(
      brand: { elemMatch: { name: { eq: $brandName } } }
    ) {
      ...HeaderV2
    }
    footer: contentfulFooter(brand: { name: { eq: $brandName } }) {
      ...Footer
    }
    locales: allLocale(filter: { language: { eq: "en" } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

Search.whyDidYouRender = true
export default Search
